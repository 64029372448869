import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/wanderpokal/2015/01.jpg"
import Image02 from "../../../images/wanderpokal/2015/02.jpg"
import Image03 from "../../../images/wanderpokal/2015/03.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Results from "../../../components/results"

const Wanderpokal2015 = props => {
  const resultHerren = [
    { verein: "»Wetterpiraten« Ober-Hörgern 1", ringe: 726 },
    { verein: "Freundeskreis »Burg und Stadt Münzenberg« 1", ringe: 714 },
    { verein: "HSG Münzenberg-Gambach 3. Mannschaft", ringe: 706 },
    { verein: "Chrischona-Gemeinde Gambach", ringe: 699 },
    { verein: "»Wetterpiraten« Ober-Hörgern 2", ringe: 698 },
    { verein: "Freundeskreis »Burg und Stadt Münzenberg« 2", ringe: 676 },
    { verein: "FC 1949 Gambach - Vorstand", ringe: 662 },
    { verein: "Theatergruppe »Lampenfieber« Gambach", ringe: 662 },
    { verein: "»Babytreff '85« Gambach", ringe: 658 },
    { verein: "TSV 1903 Gambach - Vorstand", ringe: 650 },
    { verein: "FC 1949 Gambach - Senioren", ringe: 647 },
    { verein: "Gesangverein »Edelweiß« Gambach", ringe: 645 },
    { verein: "Würfelclub »10.000« Münzenberg	", ringe: 516 },
  ]

  const resultDamen = [
    { verein: "»Wetterpiraten« Ober-Hörgern", ringe: 714 },
    { verein: "Landfrauen Ober-Hörgern", ringe: 705 },
    { verein: "FC 1949 Gambach 1", ringe: 673 },
    { verein: "Theatergruppe »Lampenfieber« Gambach", ringe: 660 },
    { verein: "Gesangverein »Harmonie« Gambach", ringe: 658 },
    { verein: "FC 1949 Gambach 2	", ringe: 640 },
  ]

  return (
    <Layout>
      <SEO title="Wanderpokal 2015" lang="de" />
      <Container>
        <h1 className="h3">
          36. Wanderpokalschießen für Vereine der Stadt Münzenberg
        </h1>
        <hr className="featurette-divider" />
        <h6>»Wetterpiraten« Ober-Hörgern weiterhin auf Siegestour</h6>
        <p>
          (fs) Am vergangenen Wochenende starteten 19 Mannschaften zu spannenden
          Wettkämpfen anlässlich des diesjährigen Wanderpokalschießens für
          Vereine der Stadt Münzenberg, welches zum 36. Mal vom Schützenverein
          1961 Gambach durchgeführt wurde. Ausgeschossen wurden wieder ein
          Herren- und ein Damenpokal. Auf die Distanz von 10 Metern wurde mit
          dem Luftgewehr aufgelegt geschossen. Dabei wurden in der auf knapp
          vier Wochen angesetzten Trainingszeit mit insgesamt 442
          Trainingsserien über 13.000 Schuss abgegeben, bevor die Mannschaften
          dann am 24. und 25. April zu den Wettkämpfen antraten.
        </p>
        <p>
          Die Sieger der letzten fünf Jahre, die Mannschaft der »Wetterpiraten«
          Ober-Hörgern untermauerten auch diesjährig mit 726 Ringen ihre
          Favoritenrolle und gewannen den Wanderpokal. Den zweiten Platz mit
          ebenfalls sehr guten 714 Ringen belegte der Freundeskreis Burg und
          Stadt Münzenberg. Die dritte Handballmannschaft der HSG
          Münzenberg/Gambach war mit 706 Ringen erfolgreich und konnte damit
          einen sehr guten dritten Platz belegen. Den Wanderpokal der Damen
          gewannen die »Piratenbräute« der »Wetterpiraten« Ober-Hörgern, die von
          2012 bis 2014 bereits erfolgreich waren. Mit sehr guten 714 Ringen
          geht der von Willi Beppler-Alt neu gestiftete Wanderpokal für ein Jahr
          an die Wetterpiraten. Den zweiten Platz belegten die Landfrauen
          Ober-Hörgern mit einem hervorragenden Ergebnis von 705 Ringen, gefolgt
          von den Damen des FC 1949 Gambach 1 mit 673 Ringen.
        </p>
        <p>
          Insgesamt gingen 120 Herren und Damen an den Start. Die besten
          Einzelergebnisse schossen Claudia Polleichtner Landfrauen
          Ober-Hörgern) mit 187 Ringen bei den Damen und Jürgen Schneider
          (»Wetterpiraten« Ober-Hörgern) mit 183 von 200 möglichen Ringen bei
          den Herren.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Die Sieger beim diesjährigen Wanderpokalschießen für Vereine der
              Stadt Münzenberg sowie die Gewinner des Glücksschießens mit dem
              Vorsitzenden Friedhelm Schwenz.
            </small>
          </Col>
        </Row>
        <p></p>
        <p>Die gesamte Tabelle nach Abschluss der Wettkämpfe:</p>
        <h6>Herren</h6>
        <Results data={resultHerren} />
        <h6>Damen</h6>
        <Results data={resultDamen} />
        <p>
          Bei dem zum 23. Mal durchgeführten Glücksschießen, bei dem auf eine
          Glücksscheibe drei Schuss abzugeben waren, wurden die Gewinner mit
          Einkaufsgutscheinen belohnt. Den ersten Platz belegte Ulrike
          Beppler-Alt und Edzard Wrede mit jeweils 48 Punkten. Der zweite Preis
          wurde mit 46 Punkten von Sabine Diehl und Jörg Neumann und gewonnen
          und der dritte Preis ging an Tanja Seipp, die 43 Punkte erreichte
        </p>
        <p>
          Während der Wettkampftage am vergangenen Freitag und Samstag, wurden
          die Teilnehmer mit Leckereien vom Grill sowie mit selbstgebackenem
          Kuchen versorgt. Die Pokal- und Urkundenübergabe erfolgte nach den
          Endwettkämpfen am Samstagabend, anlässlich einer kleinen Feierstunde
          im Schützenhaus. Hier konnte der Vorsitzende des Schützenvereins
          Gambach, Friedhelm Schwenz, die zahlreich erschienenen
          Vereinsvertreter der teilnehmenden Mannschaften begrüßen. Dabei waren
          auch zum ersten Mal Vertreter der Chrischona-Gemeinde Gambach sowie
          der Damen des FC 1949 Gambach, die gleich mit zwei Mannschaften
          angetreten waren. Er bedankte sich für die Teilnahme der angetretenen
          Vereine und warb gleichzeitig für das nächstjährige
          Wanderpokalschießen. Nach der Urkunden- und Pokalverleihung verweilten
          die Teilnehmer noch lange in gemütlicher Runde.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image02} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Die siegreichen »Wetterpiraten« Ober-Hörgern nach der
              Pokalverleihung.
            </small>
          </Col>
        </Row>
        <p></p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image03} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Die besten Einzelschützen beim diesjährigen Wettbewerb, v.l.
              Jürgen Schneider (Wetterpiraten), 1. Vorsitzende Friedhelm
              Schwenz, Claudia Polleichtner (Landfrauen Ober-Hörgern)
            </small>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Wanderpokal2015
