import React from "react"
import { Table } from "react-bootstrap"

const Results = ({ data }) => (
  <Table striped>
    <thead>
      <tr>
        <th>#</th>
        <th>Verein</th>
        <th>Ergebnis</th>
      </tr>
    </thead>
    <tbody>
      {data.map((result, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{result.verein}</td>
          <td>{result.ringe} Ringe</td>
        </tr>
      ))}
    </tbody>
  </Table>
)

export default Results
